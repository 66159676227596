import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiserviceService } from 'src/app/apiservice.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  subscribeForm!: FormGroup;

    constructor(public formBuilder: FormBuilder, public api: ApiserviceService) { }


    ngOnInit(): void {
        this.subscribeForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]]
        });
    }



    onSubmit() {
        if (this.subscribeForm.valid) {
            const formData = new FormData();
            Object.keys(this.subscribeForm.value).forEach(key => formData.append(key, this.subscribeForm.value[key]));

            this.api.submitForm(formData).subscribe(
                () => {
                    // Handle successful response
                    window.location.reload();
                },
                error => {
                    // Handle error response
                    console.error('Error!', error);
                }
            );
        }
    }


  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  

}
