<app-navbar-two></app-navbar-two>

<div class="service-title">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="title-text text-center">
                <h2>Services</h2>

                <ul>
                    <li><a routerLink="/home">Home</a></li>
                </ul>
            </div>
        </div>
    </div>
</div>

<section class="features-section pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6" id="Audit&Legal">
                <div class="features-card feature-one" id="card-service">
                    <img src="assets/images/services-page/Audit-and-Legal.png" alt="our-services1"
                        class="service-img">
                    <h3>Audit & Legal</h3>
                    <p>We provide comprehensive legal services for businesses, offering personalized support across various aspects of business matters. Our expertise extends to financial auditing and ensures up-to-date compliance with evolving tax laws and regulations.</p>
                </div>
            </div>





            
            <div class="col-lg-4 col-sm-6" id="Loans&Investments">
                <div class="features-card feature-two" id="card-service">
                    <img src="assets/images/services-page/Loans-and-Investments.png" alt="our-services2"
                        class="service-img">
                    <h3>Loans & Investments</h3>
                    <p>We serve as a systematic intermediary, connecting lenders and investors in the business realm, with a focus on Startups and MSMEs through our platform - Invest2Loan™ . We have digitized and streamlined all processes to ensure a seamless experience.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6" id="BusinessPlanModeling">
                <div class="features-card feature-three" id="card-service">
                    <img src="assets/images/services-page/Business-Plan-Modeling.png" alt="our-services3"
                        class="service-img">
                    <h3>Business Plan Modeling</h3>
                    <p>We offer a roadmap to business success encompassing strategic planning, financial analysis, and business valuation. This comprehensive approach empowers clients to create a holistic plan, facilitating financing, informed decision-making, and the realization of long-term objectives.
                    </p>
                </div>
            </div>








            <div class="col-lg-4 col-sm-6" id="MarketResearch">
                <div class="features-card feature-four" id="card-service">
                    <img src="assets/images/services-page/Market-Research.png" alt="our-services4"
                        class="service-img">
                    <h3>Market Research</h3>
                    <p>We deliver comprehensive and tailored market insights to clients, transforming your business outlook and aiding in informed decision-making. Our market research goes beyond online data, incorporating offline field data with up-to-date information, offering a holistic perspective.
                    </p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6" id="BusinessProcessOptimisation">
                <div class="features-card feature-five" id="card-service">
                    <img src="assets/images/services-page/Business-Process-Optimization.png" alt="our-services5"
                        class="service-img">
                    <h3>Business Process Optimisation</h3>
                    <p>Helping organizations to become more agile, competitive, and adaptable in an ever-evolving business landscape. We aim to enhance operational effectiveness, reduce costs, and improve customer satisfaction, ultimately contributing to the overall success and growth of the business.
                    </p>
                </div>
            </div>
 
            <div class="col-lg-4 col-sm-6" id="Recruitment&HR">
                <div class="features-card feature-six" id="card-service">
                    <img src="assets/images/services-page/Recruitment-_HR.png" alt="our-services6"
                        class="service-img">
                    <h3>Recruitment & HR</h3>
                    <p>We offer valuable services in recruitment and employee management through our platform - HireV™ . We address the complexities of HR strategy, offering guidance on talent acquisition, compliance, and employee development to ensure effective human capital management.
                    </p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6" id="VendorManagement">
                <div class="features-card feature-six" id="card-service">
                    <img src="assets/images/services-page/Vendor-Management.png" alt="our-services7"
                        class="service-img">
                    <h3>Vendor Management</h3>
                    <p>We assist clients in various aspects of vendor management easing all processes from vendor identification to procurement through our platform - Treecom™. Our goal is to ensure a seamless vendor management.
                    </p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6" id="BusinessNetworking">
                <div class="features-card feature-six" id="card-service">
                    <img src="assets/images/services-page/Business-Networking.png" alt="our-services8"
                        class="service-img">
                    <h3>Business Networking</h3>
                    <p>We offer guidance and resources to help clients establish and maintain meaningful connections, creating opportunities for growth, collaboration, and staying ahead in a competitive business environment.</p>
                </div>
            </div>




            <div class="col-lg-4 col-sm-6" id="DigitalMarketing">
                <div class="features-card feature-six" id="card-service">
                    <img src="assets/images/services-page/Digital-Marketing.png" alt="our-services9"
                    class="service-img">
                    <h3>Digital Marketing</h3>
                    <p>We aim to help businesses leverage the power of the internet to reach and engage your target audience effectively through Amplifis™. This platform ensures a focused and competitive social media communication and avoids spillover marketing costs through micro-influencer marketing.
                    </p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6" id="HRDevelopment">
                <div class="features-card feature-six" id="card-service">
                    <img src="assets/images/services-page/HR-development.png" alt="our-services10"
                        class="service-img">
                    <h3>HR Development</h3>
                    <p>We help organizations build and maintain a positive messaging among the employees about the goals, values, vision and mission through Eilo™. This application will boost corporate social media experience while enhancing the subject matter knowledge.
                    </p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6" id="MultimediaProduction">
                <div class="features-card feature-six" id="card-service">
                    <img src="assets/images/services-page/Multimedia-production.png" alt="our-services11"
                        class="service-img">
                    <h3>Multimedia Production</h3>
                    <p>We create multimedia content for effective business communication, connecting companies with a wide audience. Our services extend to social welfare campaigns, making a positive impact on society.
                    </p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6" id="MicroInfluencerMarketing">
                <div class="features-card feature-six" id="card-service">
                    <img src="assets/images/services-page/micro-influencer-Marketing.png" alt="our-services12"
                        class="service-img">
                    <h3>Micro Influencer Marketing</h3>
                    <p>We specialize in crafting content and campaign strategies, leveraging the power of small and medium influencers to amplify engagement, and enhance your brand's digital footprint.
                    </p>
                </div>
            </div>


        </div>
    </div>
</section>

