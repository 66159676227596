import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { HashLocationStrategy, Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: HashLocationStrategy,
        }
    ]
})
export class AppComponent implements OnInit {
    location: any;
    routerSubscription: any;

    constructor(private router: Router) {
    }

    ngOnInit(){
        // this.recallJsFuntions();
    }

    // recallJsFuntions() {
    //     this.router.events
    //     .subscribe((event) => {
    //         if ( event instanceof NavigationStart ) {
    //             $('.loader-content').fadeIn('slow');
    //         }
    //     });
    //     this.routerSubscription = this.router.events
    //     .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
    //     .subscribe(event => {
    //         $.getScript('../assets/js/custom.js');
    //         $('.loader-content').fadeOut('slow');
    //         this.location = this.router.url;
    //         if (!(event instanceof NavigationEnd)) {
    //             return;
    //         }
    //         window.scrollTo(0, 0);
    //     });
    // }
}