import { Component } from '@angular/core';

@Component({
  selector: 'app-c4-model',
  templateUrl: './c4-model.component.html',
  styleUrls: ['./c4-model.component.scss']
})
export class C4ModelComponent {

}
