<app-navbar-two></app-navbar-two>

<div class="portfolio-title">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="title-text text-center">
                <h2>Portfolio</h2>

                <ul>
                    <li><a routerLink="/home">Home</a></li>
                    <li>Portfolio</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<section class="portfolio-section pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/portfolio/1.jpg" alt="portfolio image">

                        <div class="portfolio-text">
                            <h3>Online Business</h3>
                            <a routerLink="/portfolio" class="portfolio-btn">Read More <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/portfolio/2.jpg" alt="portfolio image">

                        <div class="portfolio-text">
                            <h3>Digital Marketing</h3>
                            <a routerLink="/portfolio" class="portfolio-btn">Read More <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-sm-8">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/portfolio/3.jpg" alt="portfolio image">

                        <div class="portfolio-text">
                            <h3>Business Research</h3>
                            <a routerLink="/portfolio" class="portfolio-btn">Read More <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-4">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/portfolio/4.jpg" alt="portfolio image">

                        <div class="portfolio-text">
                            <h3>Content Writing</h3>
                            <a routerLink="/portfolio" class="portfolio-btn">Read More <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-sm-8">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/portfolio/5.jpg" alt="portfolio image">

                        <div class="portfolio-text">
                            <h3>Virtual Assistant Service</h3>
                            <a routerLink="/portfolio" class="portfolio-btn">Read More <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-4">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/portfolio/6.jpg" alt="portfolio image">

                        <div class="portfolio-text">
                            <h3>SEO Service</h3>
                            <a routerLink="/portfolio" class="portfolio-btn">Read More <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>