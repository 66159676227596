<nav class="navbar navbar-expand-xl ">
    <div class="container">
        <a class="navbar-brand" routerLink="/">
            <img src="assets/images/CC-logo new 13.png" alt="logo">
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ">
                <li class="nav-item">
                    <a href="javascript:void(0)" routerLink="/home" routerLinkActive="active" class="nav-link">Home</a>
                </li>

                <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active" 
                        [routerLinkActiveOptions]="{exact: true}">About</a></li>

                <li class="nav-item"><a routerLink="/service" class="nav-link" routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: true}">Services</a></li>

                <li class="nav-item"><a routerLink="/platforms" class="nav-link" routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: true}">Platforms</a></li>

                <li class="nav-item"><a routerLink="/c4model" class="nav-link" routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: true}">C4 Business Centre</a></li>

                <li class="nav-item"><a routerLink="/news" class="nav-link" routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: true}">News</a></li>

                <li class="nav-item"><a routerLink="/careers" class="nav-link" routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: true}">Careers</a></li>

                <li class="nav-item"><a routerLink="/referral" class="nav-link" routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: true}">Referrals</a></li>

                <li class="nav-item" style="border-bottom: none;"><a routerLink="/contact" class="nav-link" routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: true}" >Contact</a></li>
            </ul>
        </div>
    </div>
</nav>


