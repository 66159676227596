import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  service: any;

  constructor() { }

  ngOnInit(): void {
  }



  services = [
    { name: 'Treecom', details: 'An aggregator platform where vendor management is smooth and hassle free for any type of services.', showDetails: false },
    { name: 'Invest2Loan', details: 'A platform to ease the access of investment and financing for startups and MSMEs. ', showDetails: false },
    { name: 'Amplifis', details: 'An end-to-end digital marketing platform to run, amplify and track your digital campaigns.', showDetails: false },
    { name: 'Eilo', details: 'A platform for businesses to provide rich corporate media experience to their workforce', showDetails: false },
    { name: 'Bizzlander', details: 'A platform for industrial clients to scout for feasible land to establish their new venture or expansion. ', showDetails: false },
    { name: 'SuppoChat', details: 'A novel model for all customer relationship management and BPO/KPO services', showDetails: false }
  ];

  toggleDetails(service: { name: string, details: string, showDetails: boolean }): void {
    service.showDetails = !service.showDetails;
 }

 


  

 c4ModelItems = [
  { name: 'Compliance', icon: 'Complaints.ico', description: 'Handling your regulatory journey — from initial registration to audits and financial statements and ensuring your businesses are protected by necessary legal support. ', showDescription: false },
  { name: 'Capital', icon: 'Capital.ico', description: 'Unlocking financial opportunities for you. We specialize in securing investments and loans from diverse sources, empowering businesses for sustained growth.', showDescription: false },
  { name: 'Consumer', icon: 'Consumer.ico', description: 'Designing or optimizing business and revenue modeling,  consumer engagement, talent acquisition, BPO, KPO, and strategic technical training partnerships to keep your workforce competitive.', showDescription: false },
  { name: 'Communication', icon: 'Communication.ico', description: 'Amplifying your brand in the digital landscape with our IT, communication and digital marketing teams focused on your digital channels, product/company branding and ORM.', showDescription: false }
];

toggleDescription(item: any) {
  item.showDescription = !item.showDescription;
}


}
