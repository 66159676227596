import { trigger, state, style, animate, transition } from '@angular/animations';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
  animations: [
    trigger('countAnimation', [
      state('void', style({ opacity: 0, transform: 'translateY(20px)' })),
      transition(':enter', [animate('1s ease-out', style({ opacity: 1, transform: 'translateY(0)' }))]),
    ]),
    trigger('countNumAnimation', [
      state('in', style({ opacity: 1, transform: 'translateY(0)' })),
      transition('void => *', [style({ opacity: 0, transform: 'translateY(20px)' }), animate('1s ease-out')]),
    ]),
  ],

})
export class AboutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.startCounting();
    
  }


  yearsOfExperience = 0;
  numberOfExperts = 0;
  numberOfClients = 0;
  numberOfPartners = 0;

  private startCounting(): void {
    this.animateCount(2, 'yearsOfExperience');
    this.animateCount(45, 'numberOfExperts');
    this.animateCount(20, 'numberOfClients');
    this.animateCount(3, 'numberOfPartners');
  }

  private animateCount(targetValue: number, propertyName: string): void {
    let startValue = 0;
    const steps = 100; // Adjust the number of steps for smoother animation
    const increment = targetValue / steps;

    const interval = setInterval(() => {
      startValue += increment;
      this[propertyName] = Math.floor(startValue);

      if (startValue >= targetValue) {
        clearInterval(interval);
      }
    }, 20); // Increase the interval duration (e.g., from 10 to 20) for a slower animation
  }
}