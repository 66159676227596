import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiserviceService {
  // private scriptURL = 'https://script.google.com/macros/s/AKfycbyR3rcUmzPfj8IWI47QkFYvCwi5tKzkiDjUXMbUZmECz1576rYfNgApSiY7nKJwAti0dw/exec';
  private scriptURL = '';

  constructor( public http :HttpClient ) { }

  submitForm(formData: FormData){
    return this.http.post(this.scriptURL, formData).pipe(catchError(this.handleError));
}

private handleError(error: HttpErrorResponse){
   return throwError(error.message || 'Server Error');
}
}
