import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactComponent } from './components/pages/contact/contact.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { PortfolioComponent } from './components/pages/portfolio/portfolio.component';
import { ServiceComponent } from './components/pages/service/service.component';
import { AboutComponent } from './components/pages/about/about.component';
import { C4ModelComponent } from './components/pages/c4-model/c4-model.component';
import { PlatformsComponent } from './components/pages/platforms/platforms.component';
import { NewsComponent } from './components/pages/news/news.component';
import { CareersComponent } from './components/pages/careers/careers.component';
import { ReferralComponent } from './components/pages/referral/referral.component';
import { HomeComponent } from './components/pages/home/home.component';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';

const routes: Routes = [
  { path: '', redirectTo:'/home',pathMatch:'full'},
  {path:'home',component:HomeComponent},
  { path: 'about', component: AboutComponent },
  { path: 'service', component: ServiceComponent },
  { path: 'portfolio', component: PortfolioComponent },
  { path: 'error', component: ErrorComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'c4model', component: C4ModelComponent },
  {path: 'platforms', component:PlatformsComponent},
  {path: 'news', component: NewsComponent},
  {path: 'careers', component:CareersComponent},
  {path: 'referral', component: ReferralComponent},
  // Here add new pages component

  // { path: '**', component: ErrorComponent } // This line will remain down from the whole pages component list
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false, scrollPositionRestoration: 'top' })],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  exports: [RouterModule]
})
export class AppRoutingModule { }