<app-navbar-two></app-navbar-two>

<div class="about-title">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="title-text text-center">
        <h2>Platforms</h2>
        <ul>
          <li><a routerLink="/home">Home</a></li>
        </ul>
      </div>
    </div>
  </div>
</div>


<div class="platformsPage-section">
  <div class="container">
    <div class="row">

      <div class="col-lg-6 col-md-6 col-12">
        <div class="platform-box">
          <div class="platform-image">
            <img src="assets/images/Platform/Platform-200/Treecom1-200.png" alt=""  style="width: 24rem;">
          </div>
          <div class="platform-content">
            <h4 class="title">Treecom</h4>
            <div class="subtitle">
              An aggregator platform where vendor management is smooth and hassle free for any type of services.
            </div>
          </div>
        </div>
      </div>





      <div class="col-lg-6 col-md-6 col-12">
        <div class="platform-box">
          <div class="platform-image">
            <img src="assets/images/Platform/Platform-200/HireV-200.png" alt=""  style="width: 24rem;">
          </div>
          <div class="platform-content">
            <h4 class="title">HireV</h4>
            <div class="subtitle">
              A platform designed to serve as a hub of skilled individuals and helps to streamline the hiring process.
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6 col-md-6 col-12">
        <div class="platform-box">
          <div class="platform-image">
            <img src="assets/images/Platform/Platform-200/Offer Block Resize.png" alt="" class="platform-img" >
          </div>
          <div class="platform-content">
            <h4 class="title">OfferBlock</h4>
            <div class="subtitle">
              A platform to optimize the company’s onboarding process, saving you time and effort.
            </div>
          </div>
        </div>
      </div>














      <div class="col-lg-6 col-md-6 col-12">
        <div class="platform-box">
          <div class="platform-image">
            <img src="assets/images/Platform/Platform-200/inverst2lone1-200.png" alt=""  style="width: 20rem;">
          </div>
          <div class="platform-content">
            <h4 class="title">Invest2Loan</h4>
            <div class="subtitle">
              A platform to ease the access of investment and financing for startups and MSMEs.
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6 col-md-6 col-12">
        <div class="platform-box">
          <div class="platform-image">
            <img src="assets/images/Platform/Platform-200/Amplifis.jpeg" alt=""  style="width: 21.5rem;">
          </div>
          <div class="platform-content">
            <h4 class="title">Amplifis</h4>
            <div class="subtitle">
              An end-to-end digital marketing platform to run, amplify and track your digital campaigns.
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6 col-md-6 col-12">
        <div class="platform-box">
          <div class="platform-image">
            <img src="assets/images/Platform/Platform-200/Eilo.jpeg" alt=""  style="width: 21rem;">
          </div>
          <div class="platform-content">
            <h4 class="title">Eilo</h4>
            <div class="subtitle">
              A platform for businesses to provide rich corporate media experience to their workforce.
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6 col-md-6 col-12">
        <div class="platform-box">
          <div class="platform-image">
            <img src="assets/images/Platform/Platform-200/BizzlanderResize1.png" alt="" style="width: 24rem;">
          </div>
          <div class="platform-content">
            <h4 class="title">Bizzlander</h4>
            <div class="subtitle">
              A platform for industrial clients to scout for feasible land to establish their new venture or expansion.
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6 col-md-6 col-12">
        <div class="platform-box">
          <div class="platform-image">
            <img src="assets/images/Platform/Platform-200/Suppochat.jpeg" alt="" style="width: 20rem;">
          </div>
          <div class="platform-content">
            <h4 class="title">SuppoChat</h4>
            <div class="subtitle">
              A novel model for all customer relationship management and BPO/KPO services.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>