import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxScrollTopModule } from 'ngx-scrolltop';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { NavbarTwoComponent } from './components/layouts/navbar-two/navbar-two.component';
import { AboutComponent } from './components/pages/about/about.component';
import { ServiceComponent } from './components/pages/service/service.component';
import { PortfolioComponent } from './components/pages/portfolio/portfolio.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { C4ModelComponent } from './components/pages/c4-model/c4-model.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PlatformsComponent } from './components/pages/platforms/platforms.component';
import { NewsComponent } from './components/pages/news/news.component';
import { CareersComponent } from './components/pages/careers/careers.component';
import { ReferralComponent } from './components/pages/referral/referral.component';
import { RouterModule } from '@angular/router';
import { HomeComponent } from './components/pages/home/home.component';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    NavbarTwoComponent,
    AboutComponent,
    ServiceComponent,
    PortfolioComponent,
    ErrorComponent,
    ContactComponent,
    C4ModelComponent,
    PlatformsComponent,
    NewsComponent,
    CareersComponent,
    ReferralComponent,
    HomeComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    RouterModule.forRoot([],{useHash:true}),
    NgxScrollTopModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule
 
  ],
 
  bootstrap: [AppComponent]
})
export class AppModule { }
