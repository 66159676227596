<app-navbar-two></app-navbar-two>

<div class="main-banner banner-style-two">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-text">
                    <h1>Experience Your Perpetual Growth Cycle</h1>
                    <p>Be it Individuals, MSMEs, Corporates and Governments, Consult Cycle is your one-window solution
                        for all your business needs.</p>

                    <div class="theme-button">
                        <a routerLink="/about" fragment="c4Model-part" class="default-btn">Know More</a>
                        <a href="" class="video-btn popup-vimeo"><i class="bx bx-play"></i> Watch video</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<section class="features-section pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <h2>Our Services</h2>
            <p>Elevate your business to new heights with our valuable assistance</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="features-card feature-one">
                    <img src="assets/images/Services/General-Complaints.png" alt="our-services1" class="service-img">
                    <h3>General Compliance</h3>
                    <p>Providing strong and robust corporate governance support by helping in compliances related to
                        real estate, digital, Immigration law for both domestic and international business expansion.
                    </p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="features-card feature-two">
                    <img src="assets/images/Services/Taxaition.png" alt="our-services2" class="service-img">
                    <h3>Taxation</h3>
                    <p>Providing auditing and tax filing services to ensure financial compliance. Additionally, we offer
                        guidance on potential tax exemptions and benefits.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="features-card feature-three">
                    <img src="assets/images/Services/Legal-Advisary.png" alt="our-services3" class="service-img">
                    <h3>Legal Advisory</h3>
                    <p>Offering comprehensive legal support to clients such as Corporate Law, Taxation, Arbitration, IP,
                        Due Diligence, Contracts, Mergers & Acquisition.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="features-card feature-four">
                    <img src="assets/images/Services/Loan-_-Invesment.png" alt="our-services4" class="service-img">
                    <h3>Loans & Investments</h3>
                    <p>Simplifying the fundraising process by acting as a bridge between MSMEs and strategic
                        investors/banks.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="features-card feature-five">
                    <img src="assets/images/Services/Buisness-_-Revenue-model.png" alt="our-services5"
                        class="service-img">
                    <h3>Business & Revenue Modeling</h3>
                    <p>Crafting robust and practical business and revenue plans enhanced by our expertise in market
                        analysis, forecasting, and business modeling skills.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="features-card feature-six">
                    <img src="assets/images/Services/Market-Research.png" alt="our-services6" class="service-img">
                    <h3>Marketing Strategy</h3>
                    <p>Elevating your brand with data-driven, tech-enabled and tailored marketing strategies and build
                        lasting customer relationships.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="features-card feature-six">
                    <img src="assets/images/Services/Buisness-Optimization.png" alt="our-services7" class="service-img">
                    <h3>Business Process Optimization</h3>
                    <p>Creating leverage for you and your investors by restructuring existing business models that are
                        peripheral to core functions.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="features-card feature-six">
                    <img src="assets/images/Services/Corprate-Communication.png" alt="our-services8"
                        class="service-img">
                    <h3>Corporate Communications</h3>
                    <p>Designing and executing corporate campaigns for industrial leaders and companies to enhance your
                        digital media presence, brand value among the society.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="features-card feature-six">
                    <img src="assets/images/Services/Green-Communication.png" alt="our-services9" class="service-img">
                    <h3>Green Communications</h3>
                    <p>Strategizing organization-specific campaigns to promote environmental-resilient actions,
                        technologies and governance with a commitment to fighting climate change.</p>
                </div>
            </div>
        </div>
    </div>
</section>





<section class="about-section about-style-two pb-100">
    <div class="container-fluid">
        <div class="row">
            <div class="about-image" style="background-image: url(assets/New/HomeWhoWeAre.jpeg);"></div>
            <div class="col-lg-7 offset-lg-5 p-0">
                <div class="about-text">
                    <div class="section-title1">
                        <h2 class="name-hit">Who We Are</h2>
                    </div>
                    <p class="name-para" id="name-para">Consult Cycle is a one-stop solution for all your business
                        needs. We will be your extended arm
                        easing the business operations and grooming business models to scale up to the next level.</p>
                    <div class="theme-button">
                        <a routerLink="/about" class="default-btn">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



<section class="about-section about-style-two pb-100" id="new-whatwedo">
    <div class="container-fluid">
        <div class="row">
            <div class="about-image offset-lg-6 p-0" id="whatWeDo-newimg"
                style="background-image: url(assets/images/C4/C4\(1000x587\).jpg);">
            </div>
        </div>
    </div>
</section>





<section class="about-section about-style-two pb-100" id="whatWeDo-section">
    <div class="container-fluid">
        <div class="row">
            <div class="about-image offset-lg-6 p-0" id="about-image1">
            </div>
            <div class="col-lg-7  p-0">
                <div class="about-text1">
                    <div class="section-title1">
                        <h2 class="name-hit">What We Do</h2>
                    </div>
                    <p class="name-para" id="name-para">Any company has to work with 10+ separate vendors/companies to
                        fulfill your
                        operational needs. We are here to change it.</p>
                    <p class="name-para" id="name-para">We streamline your critical business processes through our C4
                        model powered by
                        machine learning and big data.</p>
                </div>
            </div>
        </div>
    </div>
</section>




<section class="portfolio-section pt-100 pb-70" id="new-portfolio">
    <div class="container">
        <div class="section-title text-center">
            <h2 class="py-4">Our platforms</h2>
        </div>
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/images/Platform/Treecom.jpeg" alt="portfolio image">
                        <div class="portfolio-text">
                            <h3>Treecom</h3>
                            <P class="platform-para">An aggregator platform where vendor management is smooth and hassle
                                free for any type of services.</P>
                        </div>
                    </div>
                </div>
            </div>






            <div class="col-lg-3 col-sm-6">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/images/Platform/HireV.jpg" alt="portfolio image">

                        <div class="portfolio-text">
                            <h3>HireV</h3>
                            <p class="platform-para">A platform designed to serve as a hub of skilled individuals and
                                helps to streamline the hiring process.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/images/Platform/OfferBlock 1.jpeg" alt="portfolio image">

                        <div class="portfolio-text">
                            <h3>OfferBlock</h3>
                            <p class="platform-para">A platform to optimize the company’s onboarding process, saving you
                                time and effort.</p>
                        </div>
                    </div>
                </div>
            </div>







            <div class="col-lg-3 col-sm-6">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/images/Platform/Invest2 loan.jpeg" alt="portfolio image">

                        <div class="portfolio-text">
                            <h3>Invest2Loan</h3>
                            <p class="platform-para">A platform to ease the access of investment and financing for
                                startups and MSMEs.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/images/Platform/Amplifis.jpeg" alt="portfolio image">

                        <div class="portfolio-text">
                            <h3>Amplifis</h3>
                            <p class="platform-para">An end-to-end digital marketing platform to run, amplify and track
                                your digital campaigns.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/images/Platform/Eilo.jpeg" alt="portfolio image">

                        <div class="portfolio-text">
                            <h3>Eilo</h3>
                            <p class="platform-para">A platform for businesses to provide rich corporate media
                                experience to their workforce.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/images/Platform/Bizzlander1.jpeg" alt="portfolio image">

                        <div class="portfolio-text">
                            <h3>Bizzlander</h3>
                            <p class="platform-para">A platform for industrial clients to scout for feasible land to
                                establish their new venture or expansion.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/images/Platform/Suppochat.jpeg" alt="portfolio image">

                        <div class="portfolio-text">
                            <h3>SuppoChat</h3>
                            <p class="platform-para">A novel model for all customer relationship management and BPO/KPO
                                services.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>






<div class="pricing-section  pb-70">
    <div class="container">
        <div class="section-title text-center">
            <h2>Pricing Plans</h2>
            <span>For Indian Clients</span>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="pricing-card">
                    <div class="price-header text-center">
                        <h2>Social Media<br>Support</h2>
                        <h3><sup>&#8377;</sup>15000<span>/Month</span></h3>
                        <i class="flaticon-startup"></i>
                    </div>

                    <div class="pricing-feature">
                        <ul>
                            <li><i class='bx bx-check'></i> 2 social media platforms</li>
                            <li><i class='bx bx-check'></i> 3 unique posts per day</li>
                            <li><i class='bx bx-check'></i> Video additionally chargeable </li>
                        </ul>
                    </div>

                    <div class="theme-button text-center">
                        <a routerLink="/contact" class="default-btn">Get Started</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="pricing-card price-card-two">
                    <div class="price-header text-center">
                        <h2>Multimedia<br>Support</h2>
                        <span class="text-white">Negotiable</span>
                        <i class="flaticon-startup"></i>
                    </div>

                    <div class="pricing-feature">
                        <ul>
                            <li><i class='bx bx-check'></i> Video production</li>
                            <li><i class='bx bx-check'></i> Graphic design</li>
                            <li><i class='bx bx-check'></i> Ad design</li>
                            <li><i class='bx bx-check'></i> Virtual tours</li>
                        </ul>
                    </div>

                    <div class="theme-button text-center">
                        <a routerLink="/contact" class="default-btn">Get Quote</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0 ">
                <div class="pricing-card price-card-three">
                    <div class="price-header text-center">
                        <h2>Business Remodeling & Scaling Support</h2>
                        <h3><sup>&#8377;</sup>30000<span>/Month</span></h3>
                        <i class="flaticon-startup"></i>
                    </div>

                    <div class="pricing-feature">
                        <ul>
                            <li><i class='bx bx-check'></i> Operational assessment</li>
                            <li><i class='bx bx-check'></i> Business model analysis</li>
                            <li><i class='bx bx-check'></i> Infrastructure redesign report</li>
                            <li><i class='bx bx-check'></i> Business expansion report</li>
                        </ul>
                    </div>

                    <div class="theme-button text-center">
                        <a routerLink="/contact" class="default-btn">Get Started</a>
                    </div>
                </div>
            </div>
        </div>

        <h2 class="text-center py-3">For Overseas Clients</h2>
        <div class="theme-button text-center">
            <a routerLink="/contact" class="default-btn">Get Quote</a>
        </div>
    </div>
</div>



<div class="contact-section pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 p-0 contact-img">
                <img src="assets/images/Askus.jpeg" alt="contact">
            </div>

            <div class="col-lg-6 p-0">
                <div class="contact-form">
                    <div class="contact-text">
                        <h3>We Love to Hear From You</h3>
                    </div>

                    <form id="contactForm">
                        <div class="row">
                            <div class="col-md-12 col-sm-6">
                                <div class="form-group mb-3">
                                    <input type="text" name="name" id="name" class="form-control" placeholder="Name">
                                </div>
                            </div>

                            <div class="col-md-12 col-sm-6">
                                <div class="form-group mb-3">
                                    <input type="email" name="email" id="email" class="form-control"
                                        placeholder="Email">
                                </div>
                            </div>

                            <div class="col-md-12 col-sm-6">
                                <div class="form-group mb-3">
                                    <input type="tel" name="phone" id="phone" class="form-control"
                                        placeholder="Phone number">
                                </div>
                            </div>

                            <div class="col-md-12 col-sm-6">
                                <div class="form-group mb-3">
                                    <input type="text" name="organization" id="organization" class="form-control"
                                        placeholder="Organization">
                                </div>
                            </div>

                            <div class="col-md-12 col-sm-6">
                                <div class="form-group mb-3">
                                    <input type="text" name="msg_subject" id="msg_subject" class="form-control"
                                        placeholder="Subject">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <textarea name="message" class="message-field" id="message" cols="30" rows="5"
                                        placeholder="Message"></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn contact-btn">Send Message</button>
                            </div>
                        </div>
                    </form>

                    <img src="assets/images/3.png" class="contact-shape" alt="shape">
                </div>
            </div>
        </div>
    </div>
</div>




<link rel="icon" type="image/x-icon" href="assets/c4-ico/your-icon.ico">