<app-navbar-two></app-navbar-two>

<div class="comming-soon-section">
    <div class="container p-0">
        <div class="row">
           <div class="col-xl-6 col-lg-12">
               <div class="comming-soon-item">
                 <div class="content">
                     <h2 class="title">news: Coming Soon</h2>
                     <p>This page is under construction. We’ll be here soon with exciting news about us shortly.</p>
                 </div>
                
                 <div class="subscribe-form style-01">
                  <form class="newsletter-form" id="comming-letter">
                     <div class="input-group">
                         <input type="email" class="form-control" placeholder="Enter Your Email" name="EMAIL" id="comming-input">
                         <div class="input-icon">
                             <button class="btn secondary" type="submit"> <span id="btn-commingsoon">send</span>
                                 <i id="ico-comming" class='bx bxs-paper-plane bx-tada'></i>
                             </button>
                         </div>
                     </div>
                 </form>
              </div>
                 <ul class="social-area">
                    <p>Connect With Us :</p>
                     <li><a href="#0"><i class="flaticon-facebook plat-icon"></i></a></li>
                     <li><a href="#0"><i class="flaticon-twitter plat-icon"></i></a></li>
                     <li><a href="#0"><i class="flaticon-linkedin plat-icon"></i></a></li>
                     <li><a href="#0"><i class="flaticon-instagram plat-icon"></i></a></li>
                 </ul>
               </div>
           </div>
           <div class="col-lg-6">
               <img src="assets/images/comming soon1.png" class="bg-comming" alt="">
           </div>
        </div>
    </div>
 </div>