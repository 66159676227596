<app-navbar-two></app-navbar-two>

<div class="about-title">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="title-text text-center">
        <h2>About Us</h2>

        <ul>
          <li><a routerLink="/home">Home</a></li>
        </ul>
      </div>
    </div>
  </div>
</div>

<section class="about-section pb-100">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 ">
        <div class="about-image">
          <img src="assets/New/About2.jpeg" alt="about image">
        </div>
      </div>

      <div class="col-lg-6 ">
        <div class="about-text" id="c4Model-part">
          <h2>The Smart Way to Make the Business Successful</h2>
          <p class="about-para">Started in 2022 after exploring the needs and challenges of a rushing business world,
            Consult
            Cycle supports MSMEs and Corporates to develop a platform for optimizing and scaling the
            businesses to the next level. <br> We act as a one-stop solution for Corporates, MSMEs and Individuals
            through
            our C4 model: Compliance, Capital, Consumer and Communications.</p>


          <div class="section-title" id="c4Model-part">
            <h2>What is C4?</h2>
          </div>

          <p>To establish a strong presence in any industry, companies often find themselves managing various tasks
            that, while crucial, are not directly related to your core business functions. This entails collaborating
            with a multitude of vendors, often more than 10 vendors. These processes divert resources and impose
            significant costs, especially on Micro, Small, and Medium Enterprises (MSMEs).
          </p>
        </div>

      </div>
    </div>
  </div>
</section>


<section class="c4-model1">
  <div class="container c4-container">
    <div class="row border  highlight-first">
      <div class="col-lg-4 ">
        <div class="black-box1">
          <h3 style="color: white;">Current Operational Model</h3>
        </div>
        <div class="c4-para1 ">
          <p>
            Most of the businesses are burdened with non-functional and non-core activities under 10+ verticals for
            which more than 100+ vendors are searched to onboard 10 vendors. This costs the entities in millions
            collectively affecting their overall growth. However, investing resources in these areas are inevitable as
            there has been no alternative to outsource and streamline all these responsibilities in one click.
          </p>
        </div>
      </div>
      <div class="col-lg-8">
        <img src="assets/images/C4/aboutc4-1.jpg" alt="" class="whatWeDo-img">
      </div>
    </div>

  </div>
</section>




<section class="c4-model2">
  <div class="container c4-container">
    <div class="row border  highlight-first">

      <div class="col-lg-4   order-lg-2">
        <div class="black-box2 ">
          <h3 style="color: white;">Our C4 Model</h3>
        </div>
        <div class="c4-para2">
          <p>
            With Consult Cycle’s C4 Model covering the Compliance, Capital, Consumer, and Communication-related
            responsibilities, we provide holistic support through our 8 platforms with customized solutions for each
            vertical to unburden the client. This approach entirely cuts down the complex operational tasks costing
            millions away from the client's day-to-day operations. This will free up resources, increase revenue,
            enhance business efficiency and scale up to the next level.
          </p>
        </div>
      </div>

      <div class="col-lg-8 order-lg-1">
        <img src="assets/images/C4/CC-Img2optA1.jpg" alt="" class="aboutc4-2img">
      </div>

    </div>
  </div>

</section>




<div class="counter-section-area">
  <h4 id="Counter-text">Our stories in numbers</h4>
  <div class="container">
    <div class="counter-section-inner">
      <div class="row justify-content-center">
        <div class="col-lg-3 col-md-6 counder-border1" [@countAnimation] [@countNumAnimation]>
          <div class="single-counterup-01 style-04">
            <div class="content">
              <div class="count-wrap"><span class="count-num">{{ yearsOfExperience }}</span></div>
              <h4 class="title-counter">Years of Experience</h4>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 counder-border2" [@countAnimation] [@countNumAnimation]>
          <div class="single-counterup-01 style-02">
            <div class="content">
              <div class="count-wrap"><span class="count-num">{{ numberOfExperts }}</span></div>
              <h4 class="title-counter">Experts</h4>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 counder-border3" [@countAnimation] [@countNumAnimation]>
          <div class="single-counterup-01 style-04">
            <div class="content">
              <div class="count-wrap"><span class="count-num">{{ numberOfClients }}</span></div>
              <h4 class="title-counter">Clients</h4>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6" [@countAnimation] [@countNumAnimation]>
          <div class="single-counterup-01 style-01">
            <div class="content">
              <div class="count-wrap"><span class="count-num">{{ numberOfPartners }}</span></div>
              <h4 class="title-counter">Partners</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- 
  <div class="video-style-two video-style-three">
    <div class="container-fluid">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="video-text text-center">
                    <a href="https://www.youtube.com/watch?v=Fbps7_mkIT0" class="popup-vimeo"><i
                            class="bx bx-play"></i></a>
                    <p>Watch our video</p>
                </div>
            </div>
        </div>
    </div>
</div> -->