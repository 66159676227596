

<div class="newsletter-section">
    <div class="container">
        <div class="newsletter-area">
            <h2>Subscribe for our updates</h2>
            <div class="col-lg-6 offset-lg-3 p-0">
                <form class="newsletter-form" [formGroup]="subscribeForm" (ngSubmit)="onSubmit()" >
                <!-- <form class="newsletter-form" [formGroup]="subscribeForm" (ngSubmit)="onSubmit()" method="post" action="https://script.google.com/macros/s/AKfycbyR3rcUmzPfj8IWI47QkFYvCwi5tKzkiDjUXMbUZmECz1576rYfNgApSiY7nKJwAti0dw/exec" name="subscribe-form"> -->
                    <div class="input-group">
                        <input type="email" name="email" formControlName="email" class="form-control" placeholder="Enter Your Email" required autocomplete="off">
                        <div class="input-icon">
                            <button class="btn secondary" type="submit" onclick="onsubmit()"> <span >send</span>
                                <i class='bx bxs-paper-plane bx-tada'></i>
                            </button>
                        </div>
                    </div>
                </form>
            </div>

            <!-- <i class='bx bx-rocket '></i> -->
            <img src="assets/images/7.png" class="subscribe-shape" alt="shape">
        </div>
    </div>
</div>









<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="footer-widget">
                    <div class="footer-logo">
                        <a routerLink="/"><img src="assets/images/CC-logo new 13.png" alt="logo"></a>
                    </div>
                    <p>A one-stop solution for businesses to obtain all services under Compliance, Capital, Consumer and
                        Communication to fulfill your business needs.</p>
                    <div class="footer-social">
                        <a href="https://www.facebook.com/ConsultCycle/reviews" target="_blank"><i class="flaticon-facebook"></i></a>
                        <a href="https://twitter.com/ConsultCycle_HQ" target="_blank"><i class="flaticon-twitter"></i></a>
                        <a href="https://www.instagram.com/consultcycle_com/ " target="_blank"><i class="flaticon-instagram"></i></a>
                        <a href="https://www.linkedin.com/company/78462342/admin/feed/recommend/" target="_blank"><i class="flaticon-linkedin"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="footer-widget pl-75">
                    <h3>Services</h3>

                    <ul>
                        <li><a routerLink="/service" (click)="scrollToTop()" fragment="Audit&Legal"><i class="flaticon-right"></i> Compliance</a></li>
                        <li><a routerLink="/service" (click)="scrollToTop()" fragment="Loans&Investmentsh"><i class="flaticon-right"></i> Capital</a></li>
                        <li><a routerLink="/service" (click)="scrollToTop()" fragment="MarketResearch"><i class="flaticon-right"></i> Consumer</a></li>
                        <li><a routerLink="/service" (click)="scrollToTop()" fragment="DigitalMarketing"><i class="flaticon-right"></i> Communication</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="footer-widget pl-75">
                    <h3>Quick Links</h3>

                    <ul>
                        <li><a routerLink="/" (click)="scrollToTop()"><i class="flaticon-right"></i> Home</a></li>
                        <li><a routerLink="/about" (click)="scrollToTop()"><i class="flaticon-right"></i> About</a></li>
                        <li><a routerLink="/service" (click)="scrollToTop()"><i class="flaticon-right"></i> Services</a></li>
                        <li><a routerLink="/platforms" (click)="scrollToTop()"><i class="flaticon-right"></i> Platforms</a></li>
                        <li><a routerLink="/c4model" (click)="scrollToTop()"><i class="flaticon-right"></i> C4 Business Centre</a></li>
                        <li><a routerLink="/news" (click)="scrollToTop()"><i class="flaticon-right"></i> News</a></li>
                        <li><a routerLink="/careers" (click)="scrollToTop()"><i class="flaticon-right"></i> Careers</a></li>
                        <li><a routerLink="/referral" (click)="scrollToTop()"><i class="flaticon-right"></i> Referrals</a></li>
                        <li><a routerLink="/contact" (click)="scrollToTop()"><i class="flaticon-right"></i> Contact</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6 ">
                <div class="footer-widget pl-75">
                    <h3>Find Us</h3>

                    <ul>
                        <li>
                            New.No: 4, 2nd Floor, Powertec House, <br> 5th Main Road, Vijaya Nagar, Velachery, Chennai, Tamil
                            Nadu - 600042.
                        </li>
                        <li><a href="tel:+91 9750005058">
                                +91 9750005058</a></li>

                        <li><a href="mailto:contact@consultcycle.com">
                                contact&#64;consultcycle.com</a></li>

                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="copyright-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-12 lh-1 text-center">
                    <p>© 2024 Consult Cycle. All rights are reserved. Developed by<a href="https://www.arkitin.com/" target="_blank"> Arkitin</a></p>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="right-modal">
    <div class="modal fade right" id="sidebar-right" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <a routerLink="/"><img src="assets/img/logo.png" alt="logo"></a>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 p-0">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="modal-link">
                                            <a routerLink="/home"><img src="assets/img/modal/2.jpg"
                                                    alt="home one"></a>
                                            <h3><a routerLink="/home">Home Two</a></h3>
                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="modal-link">
                                            <a routerLink="/about"><img src="assets/img/modal/3.jpg" alt="home one"></a>
                                            <h3><a routerLink="/about">About</a></h3>
                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="modal-link">
                                            <a routerLink="/service"><img src="assets/img/modal/4.jpg"
                                                    alt="home one"></a>
                                            <h3><a routerLink="/service">Service</a></h3>
                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="modal-link">
                                            <a routerLink="/portfolio"><img src="assets/img/modal/5.jpg"
                                                    alt="home one"></a>
                                            <h3><a routerLink="/portfolio">Portfolio</a></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>