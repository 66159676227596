<app-navbar-two></app-navbar-two>


<div class="about-title" id="referral-bread">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="title-text text-center">
                <h2>Referrals</h2>

                <ul>
                    <li><a routerLink="/home">Home</a></li>
                </ul>
            </div>
        </div>
    </div>
</div>




<div class="contact-section pb-100" id="Referral-section">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 p-0 " >
                <div class="col-lg-12">
                    <div class="ref-test">
                        <p>We firmly believe in the power of connection, and we create win-win situations for all our members. We invite you to join our exclusive network. By joining us, you become a part of a dynamic community where mutual success is guaranteed.</p>
                        <h2>
                            Earn up to 10% Reward
                            For Every Project Referral
                        </h2>
                    </div>
                </div>

                <img src="assets/images/Referal.png" alt="contact" id="referral-img">
            </div>

            <div class="col-lg-6 p-0">
                <div class="contact-form">
                    <div class="contact-text">
                        <h3>We Love to Hear From You</h3>
                    </div>

                    <form id="contactForm">
                        <div class="row">
                            <div class="col-md-6 col-sm-6">
                                <div class="form-group mb-3">
                                    <input type="text" name="name" id="name" class="form-control" placeholder="Name">
                                </div>
                            </div>

                            <div class="col-md-6 col-sm-6">
                                <div class="form-group mb-3">
                                    <input type="email" name="email" id="email" class="form-control"
                                        placeholder="Email">
                                </div>
                            </div>

                            <div class="col-md-6 col-sm-6">
                                <div class="form-group mb-3">
                                    <input type="tel" name="phone" id="phone" class="form-control"
                                        placeholder="Phone number">
                                </div>
                            </div>

                            <div class="contact-text">
                                <h3>Referral details</h3>
                            </div>

                            <div class="col-md-6 col-sm-6">
                                <div class="form-group mb-3">
                                    <input type="text" name="companyname" id="companyname" class="form-control"
                                        placeholder="Company Name">
                                </div>
                            </div>

                            <div class="col-md-6 col-sm-6">
                                <div class="form-group mb-3">
                                    <input type="email" name="email" id="email" class="form-control"
                                        placeholder="Email">
                                </div>
                            </div>

                            <div class="col-md-6 col-sm-6">
                                <div class="form-group mb-3">
                                    <input type="tel" name="phone" id="phone" class="form-control"
                                        placeholder="Phone number">
                                </div>
                            </div>

                            <div class="col-md-6 col-sm-6">
                                <div class="form-group mb-3">
                                    <input type="text" name="msg_subject" id="msg_subject" class="form-control"
                                        placeholder="Subject">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <textarea name="message" class="message-field" id="message" cols="30" rows="5"
                                        placeholder="Message"></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn contact-btn">Send Message</button>
                            </div>
                        </div>
                    </form>

                    <img src="assets/images/3.png" class="contact-shape" alt="shape">
                </div>
            </div>
        </div>
    </div>
</div>